<template>
    <div>
        <LazySudosuFrame />
        <LazyImmersionFrame />
        <LazyTermsAndConditionsPopup v-if="loggedIn" />
        <LazyGDPRPopup v-if="loggedIn" />
        <LazyFooterAdminTools v-if="isLoggedAsAdmin" />
        <slot />
    </div>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {storeToRefs} from 'pinia';
    import {LazySudosuFrame, LazyTermsAndConditionsPopup, LazyGDPRPopup, LazyFooterAdminTools, LazyImmersionFrame} from '#components';
    import {useAsyncData, useAuth, useHasEnoughPriviledge} from '#imports';

    const {data} = await useAsyncData('isAdmin', async () => {
        return {isAdmin: await useHasEnoughPriviledge(['ROLE_ADMIN'])};
    });

    const auth = useAuth();
    const {loggedIn} = storeToRefs(auth);
    const isLoggedAsAdmin = computed(() => loggedIn.value && data.value?.isAdmin);
</script>
